













import Vue from 'vue';
import Component from 'vue-class-component';
import TableNavigation from '@/components/tables/navigation.vue';
import PageLayout from '@/components/page/layout.vue';
import ClusterObjectTabs from '@/components/clusters/object.tabs.vue';
import { ClusterEventsBodyListModule, ClusterEventsCarListModule, ClusterEventsFaceListModule } from '@/store/clusters';
import { ObjectsType } from '@/store/objects/get.module';
import { faceClusterEventsListModule, bodyClusterEventsListModule, carClusterEventsListModule } from '@/store';
import TableGenerator, { ITableColumn } from '@/components/tables/generator.vue';
import { TableComponentNames } from '@/components/tables/components/names';

type MultipleClusterEventsModule = ClusterEventsFaceListModule | ClusterEventsBodyListModule | ClusterEventsCarListModule;

@Component({
  components: { TableNavigation, TableGenerator, PageLayout, ClusterObjectTabs }
})
export default class ClustersPage extends Vue {
  get objects(): string {
    return this.$route.params.objects;
  }

  get state(): MultipleClusterEventsModule {
    return this.getModule();
  }

  get tableSchema() {
    const r: ITableColumn[] = [
      { label_i18n: 'id', prop: 'id', 'class-name': 'events-table__id-cell', width: 80 },
      {
        label_i18n: 'image',
        'class-name': 'cell_without_paddings',
        component: TableComponentNames.ClusterEventObjectThumbnail,
        width: 160
      },
      { label_i18n: 'episode', component: TableComponentNames.EpisodeLink },
      { label_i18n: 'cluster', component: TableComponentNames.ClusterLink },
      { label_i18n: 'attributes', component: TableComponentNames.FeaturesContent, 'min-width': 196 },
      { label_i18n: 'exact_time_comma_camera', component: TableComponentNames.EventTimeCamera, width: 160 }
    ];

    return r;
  }

  getModule(): MultipleClusterEventsModule {
    switch (this.objects) {
      case ObjectsType.Bodies:
        return bodyClusterEventsListModule;
      case ObjectsType.Cars:
        return carClusterEventsListModule;
      default:
        return faceClusterEventsListModule;
    }
  }

  rowClickHandler({ item, column }) {
    const route = column.property === 'id' ? this.state.getItemRoute(item) : this.state.getListRoute({ cluster_id: [item.id] });
    this.$router.push(route);
  }

  async deleteHandler() {
    for (let item of this.state.selectedItems) {
      try {
        await this.state.delete(item.id as any);
      } catch (e) {
        console.warn('delete.error ', e);
      }
    }
    await this.state.get();
  }
}
